import styled from "@emotion/styled";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DateRange from "@mui/icons-material/DateRange";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Share from "@mui/icons-material/Share";
import { Rating } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { UserContext } from "src/App";

import {
  deleteDataDB,
  getAttendanceDB,
  getDataDB,
  getEventLikesDB,
  getUserInfoByHandle,
  groupByNow,
  handleSaveEventLikes,
  relatedPict,
  rootDomain,
  saveDataDB,
} from "src/api";
import { ButtonL } from "./AppIndex";
// import { Avatar } from "@mui/material";
import {
  Edit,
  FilterAlt,
  LockOpen,
  LockPerson,
  PushPin,
  RemoveRedEye,
  Verified,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Grid,
  Link,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FunnelComponent } from "./FunnelComponent";
let _ = require('lodash')


const Card = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
    allAccess,
    funnel = {},
    pins = [],
    allMyEvents = [],
    myLikes,
  } = myContext;
  const {
    imagen,
    item,
    handleInfo,
    setLocalAction,
    event,
    refreshing,
    setRefreshing,
    index,
  } = props;
  function formatTime(timeString: any) {
    const [hours, minutes] = timeString && timeString?.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  }

  // const [myLike, setMyLike] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);
  const [attendance, setAttendance] = useState<any>([]);
  const [eventPin, setEventPin] = useState<boolean>(false);
  const [myLike, setMyLike] = useState<boolean>(false);
  const [myRatings, setMyRatings] = useState<any>();

  const params: any = useParams<any>();
  // const location = useLocation();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId]);

  useEffect(() => {

    getDataDB({
      query: {
        eventId: item?._id,
      },
      resource: 'calendar_event_reviews',
      check: ["eventId"],
    }).then((res) => {
      const data = res?.map((ref: any) => {
        return {
          ...ref,
          rating: Number(ref?.rating)
        }
      })
      const result = _.meanBy(data, 'rating')
      if (result && result != 'NaN') {
        console.log('setMyRatings', result)
        setMyRatings(result)
      }

    })

    setMyLike(
      myLikes?.filter((res: any) => res.eventId == item?._id)?.[0]
        ? true
        : false
    );
  }, [myLikes, item?._id]);



  // console.log('guestEvents', guestEvents)

  return (
    <CardWrapperParent>
      <ImgContainer
        onClick={() => console.log(item)}
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            left: 10,
            top: 10,
            // color: 'orange',
            flexDirection: "row",
            position: "absolute",
            alignItems: "center",
          }}
        >
          <FunnelComponent {...props} index={index} item={item} />
        </div>
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
            color: item?.private ? "orange" : "gray",
            fontSize: 30,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              if (myInfo?.handle == item?.handle || myInfo?.admin == "super") {
                let text = "Sure you want to lock this page?";
                if (window.confirm(text) == true) {
                  const data = {
                    query: {
                      private: !item?.private,
                      title: item?.title,
                      handle: myInfo?.handle,
                      ...(eventId ? { eventId: eventId } : {})
                    },
                    resource: `calendar_events`,
                    check: ["handle", "eventId"],
                    myInfo,
                  };

                  saveDataDB(data).then((res: any) => {
                    // setRefreshing(true);
                  });

                  text = "You pressed OK!";
                } else {
                  text = "You canceled!";
                }
              }
            }}
          >
            {item?.private ? <LockPerson /> : <LockOpen />}
          </div>
          <div
            style={{
              marginLeft: 20,
            }}
          >
            {item?.verified == "approved" && (
              <Verified
                style={{
                  color: "orange",
                }}
              />
            )}
          </div>
        </div>
        {item?.logo && <div style={{
          position: 'absolute',
          bottom: 10,
          left: 5
        }}>
          <Avatar
            alt={"logo"}
            src={item?.logo}
            sx={{ width: 30, height: 30 }}
            style={{
              height: 40,
              width: 40,
              marginRight: -10,
              backgroundColor: 'gray',
              borderRadius: 25,
              borderWidth: 2,
            }}
          />
        </div>}
        <img src={item?.picture || item?.img || imagen} alt="" />
      </ImgContainer>
      <CardInfo>
        <CardInfoHead>
          <CardHeadChild>
            <CardHead>{item?.title}</CardHead>
            <HeadIcons
              style={{
                position: "relative",
              }}
            >
              {item?.handle == myInfo?.handle && <Edit
                onClick={() => {
                  setMyContext((existing: any) => {
                    return {
                      ...existing,
                      editInfo: false,
                      editEvent: false,
                    };
                  });
                  setTimeout(() => {
                    navigate(`event/${item?._id}/edit`)
                  }, 100)
                }}
              />}
              <PushPin
                onClick={() => {
                  if (item?.pinned) {
                    deleteDataDB({
                      eventId: item?._id,
                      resource: "calendar_event_pin",
                    }).then((res: any) => {
                      //setRefreshing(true);
                    });
                  } else {
                    const data = {
                      query: {
                        eventId: item?._id,
                        title: item?.title,
                        handle: myInfo?.handle,
                      },
                      resource: `calendar_event_pin`,
                      check: ["handle", "eventId"],
                      myInfo,
                    };
                    saveDataDB(data).then((res: any) => {
                      // setRefreshing(true);
                    });
                  }
                }}
                style={{
                  color: item?.pinned ? "orange" : "gray",
                }}
              />
              <RR
                data={{
                  text: item?.title,
                  url: `https://www.myguestnet.com/event/${item?._id}/schedule`,
                  title: `${item?.description}`,
                }}
              >
                <Share />
              </RR>
              {/* <RemoveRedEye
                style={{
                  display: "flex",
                  // fontSize: 18,
                  //   backgroundColor: myColors.brown,
                  padding: 4,
                  borderRadius: 100,
                  color: myLike ? "orange" : "gray",
                  // color: myLike ? "white" : "black",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (!myInfo?.handle) {
                    alert("Please login");
                    return;
                  }

                  const data = {
                    query: {
                      myLike: !myLike,
                      type: "event",
                      ...item,
                      eventId: item?._id,
                    },
                    resource: `calendar_event_likes`,
                    check: ["handle", "eventId"],
                    myInfo,
                  };
                  console.log("FormFields", data);

                  saveDataDB(data).then((res) => {
                    alert("Saved Like");
                    setMyLike(!myLike);
                    // setRefreshing(true);
                  });
                  // console.log('nnnnnn', item)
                  // return
                }}
              /> */}
            </HeadIcons>
          </CardHeadChild>
          {/* <CardSubHead>{item?.name}</CardSubHead> */}
        </CardInfoHead>
        {/* Date */}
        <EventDate>
          <DateRange />
          <p>
            {new Date(item.date).toLocaleString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}{" "}
            {item.startTime &&
              `·${formatTime(item.startTime)} - ${formatTime(
                item.endTime
              )} PKT`}
          </p>
        </EventDate>
        <div style={{
          width: '100%',
          // backgroundColor: 'red',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 10
          // gap: 10
        }}>
          <Rating
            onClick={() => {
              navigate(`/event/${item?._id}/reviews`);

            }}
            sx={{
              '& .MuiRating-iconFilled': {
                color: 'orange', // Color for the selected stars
              },
              '& .MuiRating-iconHover': {
                color: '#ff3d47', // Color when hovering over stars
              },
            }}
            name="size-small"
            defaultValue={0}
            value={myRatings || ''}
            size="small"
            disabled={false}
          />
          {/* <ButtonE
            onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  selectedEvent: item,
                  eventId: item?._id,
                  action: false,
                };
              });
              navigate(`/event/${item?._id}/schedule`);
            }}
            style={{ margin: 0 }}
          >
          </ButtonE> */}
          <div style={{
            marginTop: -10
          }}>
            <GuestListIcons
              {...props}
              item={item}
              attendance={attendance}
              setAttendance={setAttendance}
            />
          </div>
        </div>
        {/* <div style={{
          width: '100%',
          // backgroundColor: 'red',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: 20,
          // color: 'red'
          // gap: 10
        }}>
          <Rating
            onClick={() => {
              navigate(`/event/${item?._id}/reviews`);

            }}
            sx={{
              '& .MuiRating-iconFilled': {
                color: 'orange', // Color for the selected stars
              },
              '& .MuiRating-iconHover': {
                color: '#ff3d47', // Color when hovering over stars
              },
            }}
            name="size-small"
            defaultValue={0}
            value={myRatings || ''}
            size="small"
            disabled={false}
          />
        </div> */}
        {/* Location */}
        <EventLocation
          style={{
            // marginLeft: -10,
          }}
        >
          <div>
            <ButtonE
              onClick={() => {
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    selectedEvent: item,
                    eventId: item?._id,
                    action: false,
                  };
                });
                // navigate(`/event/${item?._id}/schedule`);
                navigate(`/event/${item?._id}/schedule`);

              }}
              style={{ margin: 0 }}
            >
              Add to Calendar
            </ButtonE>
          </div>
          <ButtonL
            onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  selectedEvent: item,
                  eventId: item?._id,
                  action: false,
                };
              });
              navigate(`/guestsite/${item?.title}`);
            }}
          >
            My Guest Site
          </ButtonL>
          {/* <ButtonL
            onClick={() => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  selectedEvent: item,
                  eventId: item?._id,
                  action: false,
                };
              });
              navigate(`/event/${item?._id}/schedule`);
            }}
          >
            My Apps
          </ButtonL> */}
        </EventLocation>
      </CardInfo>
    </CardWrapperParent>
  );
};

const Carroussel = (props: any) => {
  const { people } = props;
  const peoplePerPage = 3; // Number of people per page
  const [currentPage, setCurrentPage] = useState(1);
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    // eventTab,
    myInfo,
    myInfo: { active = "2" },
  } = myContext;
  // Calculate total number of pages
  const totalPages = Math.ceil(people.length / peoplePerPage);

  // Calculate starting and ending index for people on current page
  const startIndex = (currentPage - 1) * peoplePerPage;
  const endIndex = startIndex + peoplePerPage;

  // Slice people array to get people for current page
  const currentPeople = people.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle next page
  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Function to handle previous page
  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Pagination logic to determine which page numbers to display
  const getPageNumbers = () => {
    const displayPages = [];
    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        displayPages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        displayPages.push(1, 2, 3);
        displayPages.push("...");
        displayPages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        displayPages.push(1);
        displayPages.push("...");
        for (let i = totalPages - 2; i <= totalPages; i++) {
          displayPages.push(i);
        }
      } else {
        displayPages.push(1);
        displayPages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          displayPages.push(i);
        }
        displayPages.push("...");
        displayPages.push(totalPages);
      }
    }
    return displayPages;
  };

  useEffect(() => {
    if (myInfo?.handle)
      getEventLikesDB({
        handle: myInfo?.handle,
      }).then((res: any) => {
        if (res?.[0])
          setMyContext((existing: any) => {
            return {
              ...existing,
              myLikes: res,
            };
          });
      });
  }, [myInfo?.handle]);

  return (
    <MainContainer>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-start",
          maxWidth: 930,
        }}
      ></div>
      <span style={{padding: 16}}>
        {`${startIndex + 1} - ${Math.min(endIndex, people.length)}`}
        <span>{` of ${people.length} events`}</span>
      </span>
      <CardsContainer>
        {currentPeople.map((res: any, index: number) => {
          if (res?.private && myInfo?.handle != res?.handle) {
            return;
          }
          return (
            <Card
              key={index}
              event={people}
              {...props}
              imagen={
                res?.image ||
                res?.img ||
                relatedPict[index] ||
                "https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg"
              }
              item={res}
              index={index}
            />
          );
        })}
      </CardsContainer>
      <Pagination>
        <button onClick={prevPage} disabled={currentPage === 1}>
          <ChevronLeft />
        </button>
        {getPageNumbers().map((page, index) => (
          <PageNumber
            key={index}
            onClick={() => {
              if (typeof page === "number") {
                handlePageChange(page);
              }
            }}
            active={currentPage === page}
            isPage={page == "..." ? true : false}
          >
            {page}
          </PageNumber>
        ))}
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          <ChevronRight />
        </button>
      </Pagination>
    </MainContainer>
  );
};

const GuestListIcons = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const {
    myInfo,
    myInfo: { active = "2" },
    allGuest = [],
    myEvents = [],
    allMyEvents,
    host = "",
    refEvent,
    tempAllGuest = [],
    tempMyEvents = [],
    searchBy = "Event",
  } = myContext;
  const { imagen, item, attendance, setAttendance } = props;

  const params: any = useParams<any>();
  // const [attendance, setAttendance] = useState<any>([]);

  // console.log("hostTabs", hostTabs, eventTab, model);

  useEffect(() => {
    if (item?._id)
      getAttendanceDB({
        eventId: item?._id,
        // handle: myInfo?.handle
      }).then(async (res: any) => {
        const event =
          res &&
          res?.[0] &&
          groupByNow({
            data: res,
            key: "handle",
            id: 2,
          });
        const guestInfo: any =
          event &&
          Object.keys(event)?.length > 0 &&
          (await Promise.all(
            Object.keys(event)?.map(async (hand: any) => {
              return await getUserInfoByHandle(hand).then((user: any) => {
                console.log("hostTabsxxccnn", user);

                if (!user) {
                  return {};
                }
                return {
                  ...user,
                  active: true,
                  eventInfo: event,
                  eventId: event?.[0]?._id,
                  title: user?.name,
                  description: event?.[0]?.title,
                  handle: user?.handle,
                  link: `/host/${user?.handle}/about`,
                  role: "collaborator",
                };
              });
            })
          ));
        console.log("getAttendanceDBuser", item?._id, guestInfo);

        setAttendance(guestInfo || []);
      });
  }, [myInfo?.handle, host?.handle, item?._id]);



  return (
    <div
      onClick={() => {
        navigate(`/host/${item.handle}/about`);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 5,
        width: '100%',
        height: 50,
        marginBottom: -10
      }}
    > <AvatarainContainer>
        <Avatar
          alt={"NA"}
          src={"https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"}
          sx={{ width: 30, height: 30 }}
          style={{
            height: 40,
            width: 40,
            marginRight: -10,
            backgroundColor: 'gray',
            borderRadius: 25,
            borderWidth: 2,
          }}
        />
      </AvatarainContainer>

      {(attendance?.length <= 3 ? attendance
        : attendance.slice(2))?.map((res: any, index: number) => {
          return (
            <AvatarainContainer>
              <Avatar
                alt={res?.name || "NA"}
                src={
                  res.img ||
                  relatedPict[index] ||
                  "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic2.jpg"
                }
                sx={{ width: 30, height: 30 }}
                style={{
                  height: 40,
                  width: 40,
                  marginRight: -10,
                  backgroundColor: 'gray',
                  borderRadius: 25,
                  borderWidth: 2,
                  borderColor: 'white',
                }}
              />
            </AvatarainContainer>
          );
        })}
    </div>
  );
};

export default Carroussel;

const AvatarainContainer = styled.div`
   shadow-color: #999
  shadow-offset: { width: 0, height: 8 }
  shadow-opacity: 0.3
  shadow-radius: 15px
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 20px 20px;
  font-family: "Poppins", sans-serif;
  span {
    width: 100%;
    max-width: 930px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    span {
      color: #828282;
    }
  }
`;
const CardWrapperParent = styled.div`
  max-width: 930px;
  height: 245px;
  width: 100%;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  border-radius: 16px;
  gap: 16px;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 756px) {
    flex-direction: column;
    height: auto; /* Reset height to auto for vertical stacking */
  }
`;
const ImgContainer = styled.div`
  position: relative;
  height: 100%;
  max-width: 280px;
  width: 100%;
  @media screen and (max-width: 756px) {
    max-width: none;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  i {
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.18);
  }
`;
export const RR = styled(RWebShare)`
  border: 12px solid green;
`;

const CardHead = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;
const HeadIcons = styled.h1`
  display: flex;
  gap: 5px;
`;
const CardSubHead = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 350px;
  text-align: left;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  button {
    border: none;
    background: none;
    color: #bcbcbc;
    transition: all 0.3s;
  }
`;

const PageNumber = styled.div<{ active: boolean; isPage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background: ${(props) => (props.active ? "#000" : "#fff")};
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: ${(props) =>
    props.active ? "#000" : props.isPage ? "#fff" : "#dbdbdb"};
  }
`;
const CardInfoHead = styled.div`
  width: 100%;
  text-align: left;
`;
const EventDate = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 756px) {
      font-size: 12px;
      text-align: left;
    }
  }
  svg {
    width: 22px;
    height: 22px;
    color: #479d83;
  }
`;
const EventLocation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    b {
      color: #2b2e39;
      font-size: 18px;
      font-weight: 700;
    }
    a {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
const ButtonE = styled.button`
  display: flex;
  gap: 12px;
  border: none;
  background: none;
  outline: none;
  color: #2b2e39;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  &:hover {
    text-decoration: underline;
  }
`;
const CardHeadChild = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  svg {
    width: 32px;
    height: 32px;
    color: gray;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: black;
    }
  }
`;
const ButtonC = styled.div`
  border: none;
  background-color: #479d83;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #57b397;
  }
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid;
  margin-bottom: 10px;
  cursor: grab;

  &:hover {
    background: gray;
  }
`;

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 10px;
  }
`;

const TaskAvatars = styled.div`
  margin-top: 10px;
`;
const DropDownFilter = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  ul {
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-decoration: none;
    margin: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    max-height: 150px;
    min-width: 150px;
    padding: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #479d83;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      text-decoration: none;
      padding: 5px 10px;
      cursor: pointer;
      background: white;
      transition: background 0.3s;
      justify-content: space-between;
      &:hover {
        background-color: #80808071;
      }
      input {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
